import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'
import service1 from '../../images/services-image1.jpg'


const PageTwo = () => {
    return (
        <Layout>
        <SEO title='Anne Arundel County CPA' />    
            <section>
                <div className='service-page-header'>
                    <div className='service-page-header-left'>
                        <h1 style={{color: `#B62020`}}>BOOKKEEPING</h1>
                        <p>
                        Bookkeeping is the recording of financial transactions, and is part of the process of 
                        accounting in business and other organizations. It involves preparing source documents 
                        for all transactions, operations, and other events of a business. Transactions include 
                        purchases, sales, receipts and payments by an individual person or an organization/corporation.
                        Why should you hire a bookkeper? Here are 3 good reasons why!
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>TIME</h3>
                        On average, a business owner can spend up to 5 hours a week updating records and making computations. And 
                        this is only during the average week, not including end-of-year reports and tax season. Time spent can 
                        jump to 10 hours during these important finance events.
                        What could you do with an extra 5 hours a week? What should you be focusing an extra 10 hours on at year’s 
                        end or at the close of your fiscal year?
                        Doing your own books is taking away valuable time from growing your business. As the business owner, this 
                        is what you should be spending time on. You are the great thinker behind the operation, the mastermind 
                        who needs to be working on the next big move. Hire a bookkeeper to take care of this operational function 
                        to save your time for what you really need to be doing.
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>PASSION</h3>
                        Is crunching numbers and composing reports really what you want to be doing for your business?
                        Most business owners get burned out so fast because they are doing too many things. Many business 
                        owners also burn out way too soon because they are forcing themselves to do things that they are really 
                        not interested in. This level of dedication and discipline is admirable. It is not, however, an intelligent 
                        use of their talents. Ask yourself what you really love doing. This is what you should be focused on. Then, 
                        hire a bookkeeper to take over the financials so you can do it.
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>GROWTH</h3>
                        Starting a business needs your full attention and focus on every single aspect. As you pass your first year and 
                        gain some stability, however, you will want to rethink your personal investment. If you are still doing 
                        everything, you won’t be able to grow. You need to pull yourself out of the tedious aspects of running your business 
                        so you can get into a position to push your business forward.
                        Growth requires that you focus on your core business needs. Devote yourself to big-picture strategy and don’t get 
                        bogged down by the little tasks that will take you away from raising up a strong business. Use your unique business 
                        talents to give your business the best care, and hire a bookkeeper to do the small stuff.
                        <br/>
                        <br/>
                        Contact me today for all of your bookkeping needs.
                        </p>
                    </div>
                    <div className='service-page-header-right'>
                        <div className='service-page-header-photo-container'>
                            <img src={service1} alt='fix this alt'/>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='contact-section'>
                    <div className='contact-section-top'>
                    <p style={{margin: `0`}}>CONTACT</p>
                    </div>
                    <div className='contact-section-bottom'>
                    <div className='contact-section-bottom__left'>
                        <p>
                        Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
                        <br/>
                        <br/>
                        or
                        <br/>
                        <br/>
                        Fill out the contact form and I'll reach out to you as soon as possible.
                        </p>
                    </div>
                    <div className='contact-section-bottom__right'>
                        <Form />
                    </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PageTwo