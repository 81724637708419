import React from 'react'
import './Form.css'

const Form = () => (
  <div className='form-container'>
  <h2 style={{margin: '0', fontSize: '1.95rem', color: '#000000'}}>Free Tax Consultation</h2>
  <p style={{margin: '0', color: '#000000', fontSize: '0.9rem'}}>it’s my pleasure to answer your accounting and tax questions</p>
  <form
  name="contact"
  method="post"
  data-netlify="true"
  data-netlify-honeypot="bot-field"
  action='/md-cpa/success'
  >
  <input type="hidden" name="form-name" value="contact" />
  <input
    name='name'
    placeholder='Name'
    maxlength="40"
    required
  />
  <input
    name='email'
    placeholder='Email'
    maxlength="75"
    required
  />
  <input
    name='tel'
    type="tel"
    placeholder='Telephone'
    maxlength="10"
    required
  />
  <textarea
    name='message'
    placeholder='Brief description of your consultation request'
  />
  <button type='submit'>submit</button>
</form>
  </div>
)

export default Form
